import '../../css/app.css';


const LandingPage = () => {
	return ( 
		<div className="min-h-screen bg-[#f9f9f9]">
			{/* Navigation */}
			<div className="px-6 py-6">
				<div className="flex justify-between items-center max-w-7xl mx-auto">
					{/* Logo */}
					<div
						onClick={() => window.location.href = '/'}
						className="flex items-center justify-center w-[60px] h-[48px] bg-white rounded-[30px] shadow-md cursor-pointer"
					>
						<img className="w-[29px] h-[32px]" src={require('../../assets/pbjelly-tight.svg').default} alt="PB & Jelly" />
					</div>
					
					{/* Menu */}
					{/* <div className="hidden md:flex items-center justify-center h-[48px] bg-white rounded-[52px] shadow-md px-8">
						<div className="flex items-center space-x-8">
							<div className="bg-black rounded-[52px] px-6 py-2">
								<span className="text-white text-xs">Home</span>
							</div>
							<span className="text-black text-xs">Services</span>
							<span className="text-black text-xs">Works</span>
							<span className="text-black text-xs">Advice</span>
							<span className="text-black text-xs">Pricing</span>
							<span className="text-black text-xs">About</span>
						</div>
					</div> */}
					
					{/* CTA Button */}
					<div
						onClick={() => window.Calendly.initPopupWidget({url: 'https://calendly.com/pbjelly/brainstorm?hide_gdpr_banner=1'})}
						className="flex items-center justify-center w-[96px] h-[48px] 
							bg-pink text-white rounded-[30px] shadow-md cursor-pointer hover:bg-white 
							hover:text-black hover:outline hover:outline-2 hover:outline-pink transition-all">
						<span className="text-xs font-bold">Let's Chat</span>
					</div>
				</div>
			</div>
			
			{/* Hero Section */}
			<div id="hero" className="flex flex-col items-center justify-center px-6 py-36">
				{/* Tag */}
				<div className="flex items-center bg-white rounded-[52px] shadow-md mb-12">
					<div className="flex items-center pl-3 pr-1 py-1 font-medium">
						<div className="mr-2">
							<img src={require('../../assets/idea-icon.svg').default} alt="Idea" width="16" height="17" />
						</div>
						<div className="text-black text-xs pt-[2px]">Introducing</div>
						<div className="ml-2 bg-black rounded-[52px] px-3 py-[2px]">
							<span className="text-white text-xs">PB & Jelly</span>
						</div>
					</div>
				</div>
				
				{/* Headline */}
				<h1 className="text-5xl md:text-6xl font-semibold text-center mb-6 max-w-4xl">
					We build <span className="text-pink">industry-changing</span> startups.
				</h1>
				
				{/* Subheading */}
				<p className="text-[#444444] text-lg md:text-xl text-center mb-12 max-w-xl">
					A boutique consultancy for early-stage ideas.
				</p>
				
				{/* CTA Button */}
				<div
					onClick={() => window.Calendly.initPopupWidget({url: 'https://calendly.com/pbjelly/brainstorm?hide_gdpr_banner=1'})}
					className="flex items-center justify-center w-[283px] h-[45px]
						bg-pink text-white rounded-[30px] shadow-md cursor-pointer hover:bg-white
						hover:text-black hover:outline hover:outline-2 hover:outline-pink transition-all">
					<span className="text-sm font-medium">Book a <u className="underline-offset-[3px]">Free</u> 60-minute Brainstorm</span>
				</div>
			</div>
		</div>
	);
}
 
export default LandingPage;